exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contactanos-tsx": () => import("./../../../src/pages/contactanos.tsx" /* webpackChunkName: "component---src-pages-contactanos-tsx" */),
  "component---src-pages-diseno-de-aplicaciones-moviles-tsx": () => import("./../../../src/pages/diseno-de-aplicaciones-moviles.tsx" /* webpackChunkName: "component---src-pages-diseno-de-aplicaciones-moviles-tsx" */),
  "component---src-pages-diseno-web-madrid-tsx": () => import("./../../../src/pages/diseno-web-madrid.tsx" /* webpackChunkName: "component---src-pages-diseno-web-madrid-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lo-que-hacemos-tsx": () => import("./../../../src/pages/lo-que-hacemos.tsx" /* webpackChunkName: "component---src-pages-lo-que-hacemos-tsx" */),
  "component---src-pages-marketing-digital-madrid-tsx": () => import("./../../../src/pages/marketing-digital-madrid.tsx" /* webpackChunkName: "component---src-pages-marketing-digital-madrid-tsx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-pages-software-personalizados-tsx": () => import("./../../../src/pages/software-personalizados.tsx" /* webpackChunkName: "component---src-pages-software-personalizados-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-tienda-online-tsx": () => import("./../../../src/pages/tienda-online.tsx" /* webpackChunkName: "component---src-pages-tienda-online-tsx" */),
  "component---src-pages-todos-nuestros-servicios-tsx": () => import("./../../../src/pages/todos-nuestros-servicios.tsx" /* webpackChunkName: "component---src-pages-todos-nuestros-servicios-tsx" */)
}

